var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "three-days-calendar-container pt-0"
  }, [_c('v-row', {
    staticClass: "header mb-3"
  }, [_c('v-col', {
    staticClass: "header-day"
  }), _vm._l(_vm.days, function (day, index) {
    return _c('v-col', {
      key: "date2-".concat(index),
      staticClass: "header-day text-center",
      attrs: {
        "date": day.fullDate
      }
    }, [_c('div', {
      staticClass: "day-names"
    }, [_vm._v(" " + _vm._s(day.dayName) + " ")]), _c('div', {
      staticClass: "day-date",
      class: {
        'today': _vm.isToday(day)
      }
    }, [_vm._v(" " + _vm._s(_vm.getDayLabel(day)) + " ")])]);
  }), _vm.loading ? _c('v-col', {
    staticClass: "flex-grow-1 px-0 py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  })], 1) : _vm._e()], 2), _vm._l(_vm.shifts, function (shift) {
    return _vm._l(_vm.getShiftItems(shift.startTime, shift.endTime), function (shiftItem, shiftItemIndex) {
      return _c('v-row', {
        key: "time-row-".concat(shiftItem.timeHuman, "-").concat(shiftItemIndex),
        staticClass: "shift-row",
        class: "shift-row-".concat(shift.name),
        attrs: {
          "align": "stretch"
        }
      }, [_c('v-col', {
        staticClass: "shift-time text-right"
      }, [_vm._v(" " + _vm._s(shiftItem.timeHuman) + " ")]), _vm._l(_vm.days, function (day, index) {
        return _c('v-col', {
          key: "date2-".concat(index),
          staticClass: "shift-slot text-center",
          attrs: {
            "date": day.fullDate
          }
        }, _vm._l(_vm.findEventsInADateAndTime(shiftItem, day.fullDate), function (event, eventIndex) {
          return _c('event', {
            key: "event-".concat(day.fullDate, "-").concat(eventIndex),
            attrs: {
              "night-view": shift.name === 'night',
              "three-days-view": "",
              "event": event
            },
            on: {
              "event:click": function eventClick($event) {
                return _vm.$emit('event:click', $event);
              }
            }
          });
        }), 1);
      })], 2);
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }