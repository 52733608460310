<template>
  <div class="three-days-calendar-container pt-0">
    <v-row class="header mb-3">
      <v-col class="header-day"> </v-col>
      <v-col
        v-for="(day, index) in days"
        :key="`date2-${index}`"
        :date="day.fullDate"
        class="header-day text-center"
      >
        <div class="day-names">
          {{ day.dayName }}
        </div>
        <div class="day-date" :class="{'today': isToday(day)}">
          {{ getDayLabel(day) }}
        </div>
      </v-col>
      <v-col v-if="loading" cols="12" class="flex-grow-1 px-0 py-0">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-col>
    </v-row>

    <template v-for="shift in shifts">
      <v-row
        class="shift-row"
        :class="`shift-row-${shift.name}`"
        align="stretch"
        :key="`time-row-${shiftItem.timeHuman}-${shiftItemIndex}`"
        v-for="(shiftItem, shiftItemIndex) in getShiftItems(
          shift.startTime,
          shift.endTime
        )"
      >
        <v-col class="shift-time text-right">
          {{ shiftItem.timeHuman }}
        </v-col>
        <v-col
          v-for="(day, index) in days"
          :key="`date2-${index}`"
          :date="day.fullDate"
          class="shift-slot text-center"
        >
          <event
            v-for="(event, eventIndex) in findEventsInADateAndTime(
              shiftItem,
              day.fullDate
            )"
            :key="`event-${day.fullDate}-${eventIndex}`"
            :night-view="shift.name === 'night'"
            three-days-view
            :event="event"
            @event:click="$emit('event:click', $event)"
          >
          </event>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import Event from '@/components/admin/partials/Calendar/Event/Event'
import dayjs from '@/plugins/dayjs'
import { groupBy } from 'lodash'

export default {
  name: 'ThreeDaysCalendar',
  components: { Event },
  props: {
    day: {
      type: Object,
      default: () => {},
      required: false
    },
    hideEmployeeRow: {
      type: Boolean,
      default: false,
      required: false
    },
    unassignedEmployees: {
      type: Array,
      default: () => [],
      required: false
    },
    overlapLimit: {
      type: Number,
      default: 2,
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  created() {
    this.init()
  },
  watch: {
    day() {
      this.init()
    }
  },
  computed: {
    currentDay() {
      return this.day
    },
    dayShiftStartTime() {
      return this.day
        .hour(5)
        .minute(0)
        .second(0)
    },
    dayShiftEndTime() {
      return this.day
        .hour(17)
        .minute(0)
        .second(0)
    },
    nightShiftStartTime() {
      return this.day
        .hour(18)
        .minute(0)
        .second(0)
    },
    nightShiftEndTime() {
      return this.day
        .add(1, 'day')
        .hour(4)
        .minute(0)
        .second(0)
    },
    shifts() {
      return [
        {
          name: 'day',
          startTime: this.dayShiftStartTime,
          endTime: this.dayShiftEndTime
        },
        {
          name: 'night',
          startTime: this.nightShiftStartTime,
          endTime: this.nightShiftEndTime
        }
      ]
    },
    allTasks() {
      return this.$store.getters.allTasks
    },
    events() {
      return groupBy(this.allTasks, 'date')
    }
  },
  data: () => ({
    days: []
  }),
  methods: {
    dayChunks(array, chunk_size) {
      return Array(Math.ceil(array.length / chunk_size))
        .fill()
        .map((_, index) => index * chunk_size)
        .map(begin => array.slice(begin, begin + chunk_size))
    },

    getShiftItems(startTime, endTime) {
      let shifts = []
      while (startTime.isSameOrBefore(endTime)) {
        shifts.push({
          timeHuman: startTime.locale('en').format('h A'),
          timeFormatted: startTime.format('HH:mm:ss'),
          from: startTime,
          to: startTime.add(1, 'hour')
        })

        startTime = startTime.add(1, 'hour')
      }
      return shifts
    },

    init() {
      let days = []

      let beginDate = this.currentDay.subtract(1, 'day')
      let endDate = this.currentDay.add(1, 'day')

      while (beginDate.isSameOrBefore(endDate, 'day')) {
        days.push({
          date: beginDate.format('DD'),
          month: beginDate.format('M'),
          dayName: beginDate.format('ddd'),
          monthName: beginDate.format('MMMM'),
          fullDate: beginDate.format('YYYY-MM-DD'),
          type: beginDate.isBefore(this.currentDay, 'month')
            ? 'trailing'
            : beginDate.isAfter(this.currentDay, 'month')
            ? 'leading'
            : 'current',
          first: beginDate.date() === 1
        })

        beginDate = beginDate.add(1, 'day')
      }
      this.days = days
    },

    findEventsInADateAndTime(shift, date) {
      let events = this.events[date]

      if (!events) return []

      let timeSlot = dayjs(`${date} ${shift.from.format('HH:mm')}:00`)

      let shiftTime = {
        startTime: timeSlot,
        endTime: timeSlot.add(1, 'hour')
      }

      return events.filter(event => {
        if (shiftTime.startTime.hour() === 5 && !event.task_start_time) {
          return event
        }

        if (event.task_start_time) {
          let eventTime = dayjs(`${date} ${event.task_start_time}:00`)

          return eventTime.isBetween(
            shiftTime.startTime,
            shiftTime.endTime,
            'hour',
            '[)'
          )
        }
        return false
      })
    },

    getDayLabel(day) {
      return day.first ? `${day.month}/${day.date}` : day.date
    },

    isToday(date) {
      return date.fullDate === dayjs().format('YYYY-MM-DD')
    }
  }
}
</script>

<style src="./ThreeDays.scss" lang="scss" scoped></style>
